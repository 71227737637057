.p-toast {
  .p-toast-message {
    &.p-toast-message-error {
      .p-toast-message-icon,
      .p-toast-icon-close {
        @apply text-white;
      }
      @apply bg-danger-700 text-white;
      border: solid #c71811;
    }
    &.p-toast-message-info {
      .p-toast-message-icon,
      .p-toast-icon-close {
        @apply text-white;
      }
      @apply bg-inform-500 text-white;
      border: solid #38b0da;
    }
    &.p-toast-message-warn {
      .p-toast-message-icon,
      .p-toast-icon-close {
        @apply text-white;
      }
      @apply bg-warning-700 text-white;
      border: solid #fa0;
    }
    &.p-toast-message-success {
      .p-toast-message-icon,
      .p-toast-icon-close {
        @apply text-white;
      }
      @apply bg-confirm-600 text-white;
      border: solid #429f28;
    }
  }

  .p-toast-message .p-toast-message-content {
    border-width: 0 0 0 0;
  }
}

.custom-toast {
  max-width: calc(100vw - 40px) !important;
}
