.mat-icon:not(.preserve-fill-color) {
  svg,
  svg path {
    fill: currentColor;
  }
}

.mat-icon {
  &:focus {
    @apply outline-none;
  }

  &-lg {
    height: 60px !important;
    width: 60px !important;
  }

  &-md {
    height: 40px !important;
    width: 40px !important;
  }

  &-sm {
    height: 20px !important;
    width: 20px !important;
  }

  &-xs {
    height: 16px !important;
    width: 16px !important;
  }
}
