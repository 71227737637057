.custom-pill {
  @apply text-xs rounded-sm min-h-full h-auto py-0 px-1 m-0 outline-none font-bold not-italic cursor-default;

  &.inform {
    @apply text-white bg-inform-600;

    &-light {
      @apply text-inform-900 bg-inform-50;
    }
  }

  &.confirm {
    @apply text-white bg-confirm-600;

    &-light {
      @apply text-confirm-900 bg-confirm-50;
    }
  }

  &.warning {
    @apply text-black bg-warning-500;

    &-light {
      @apply text-neutral-800 bg-warning-50;
    }
  }

  &.caution {
    @apply text-white bg-warning-900;

    &-light {
      @apply text-warning-900;

      background: linear-gradient(
          0deg,
          rgb(244 138 3 / 12%),
          rgb(244 138 3 / 12%)
        ),
        #fff;
    }
  }

  &.danger {
    @apply text-white bg-danger-600;

    &-light {
      @apply text-danger-900 bg-danger-50;
    }
  }

  &.help {
    @apply text-white bg-help-600;

    &-light {
      @apply text-help-900 bg-help-50;
    }
  }

  &.neutral {
    @apply text-white bg-neutral-500;

    &-light {
      @apply text-neutral-700 bg-neutral-100;
    }
  }

  &.notify {
    @apply text-white bg-notify-600;

    &-light {
      @apply text-inform-900 bg-notify-50;
    }
  }
}