.mat-checkbox {
  color: inherit;
  @apply inline-flex items-center;

  &.cdk-keyboard-focused .mat-checkbox-persistent-ripple {
    opacity: 0 !important;
  }

  &.cdk-keyboard-focused .mat-checkbox-frame {
    box-shadow: 0 0 0 3px rgba(39, 150, 220, 0.33);
  }

  .mat-checkbox-frame {
    @apply bg-white border border-neutral-300;
  }

  .mat-checkbox-frame,
  .mat-checkbox-background {
    @apply rounded-sm;
  }
}

.mat-checkbox-disabled {
  .mat-checkbox-frame {
    @apply bg-neutral-25 border-neutral-200;
  }

  .mat-checkbox-background {
    @apply bg-neutral-200;
  }

  .mat-checkbox-checkmark-path {
    @apply stroke-current #{!important};
  }

  &.mat-checkbox-checked {
    @apply text-neutral-400;
  }
}

.mat-checkbox-indeterminate .mat-checkbox-background,
.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-checked .mat-ripple-element {
  @apply bg-inform-500;
}

.mat-option-pseudo-checkbox {
  transition: background-color 90ms cubic-bezier(0, 0, 0.2, 0.1) !important;
  @apply bg-transparent #{!important};
}
