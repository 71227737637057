/* You can add global styles to this file, and also import other style files */
@use "sass:map";

@use "abstracts/all" as *;
@use "vendors/all" as *;
@use "utils" as *;
@use "base/all" as *;
@use "components/all" as *;

html,
body,
main {
  @apply scroll-smooth;

  height: 100%;
}

body {
  @apply font-inter;
  @apply text-base text-neutral-600;

  &.dark-mode {
    @apply text-neutral-200;
  }
}

@each $heading in map.keys($headings) {
  $heading-map: map.get($headings, $heading);
  .mat-toolbar #{$heading},
  #{$heading} {
    font-family: map.get($heading-map, family);
    font-size: map.get($heading-map, value);
    font-weight: map.get($heading-map, weight) !important;
    line-height: map.get($heading-map, "line-height");
    @apply text-#{map.get($heading-map, color)};
    @if $heading == "h6" {
      letter-spacing: 0.04em !important;
    } @else {
      letter-spacing: -0.02em !important;
    }
  }

  .dark-mode .mat-toolbar #{$heading},
  .dark-mode #{$heading} {
    @apply text-white;
  }
}

.subtitle {
  font-family: $rubik-font-family;
  font-size: $font-size-24px;
  letter-spacing: -0.02em;
  line-height: 28px;
}

.mat-badge-medium .mat-badge-content {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.mat-toolbar {
  color: inherit;
  font: inherit;
  @apply flex-wrap bg-transparent;
}

.mat-header-cell .mat-sort-header-button span {
  @apply truncate;
}

.mat-header-cell .mat-sort-header-container,
.mat-header-cell .mat-sort-header-button {
  @apply overflow-hidden;
}

.mat-paginator {
  .mat-select .mat-select-value-text span {
    width: 100%;
  }

  &-page-size-select {
    .mat-form-field-flex {
      min-width: 80px;
    }
  }
}

.p-paginator {
  border: none;
}
