/* stylelint-disable value-keyword-case */

// NOTE: THIS TWO VARIABLES WERE EXPOSED BY ERROR
// TO BE IMPORTED ON PREVIOUS VERSIONS. NOW THEY ARE HIDDEN
// SO WE NEED TO COPY ITS VALUES TO USE THEM.
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$theme-colors: (
  "neutral": (
    10: #f7f9fa,
    25: #f0f4f5,
    50: #e9eef0,
    100: #dee5e7,
    200: #c7d2d6,
    300: #aab7bd,
    400: #7a858d,
    500: #5b676f,
    600: #47535c,
    700: #323f49,
    800: #1f2b36,
    900: #0d161f,
  ),
  "inform": (
    50: #e7f7ff,
    100: #b9e4f9,
    200: #8dd2f3,
    300: #4fb5e9,
    400: #2796dc,
    500: #0e75cc,
    600: #0057b8,
    700: #0042ae,
    800: #02339f,
    900: #062a8f,
  ),
  "notify": (
    50: #e2fafa,
    100: #c1f7f8,
    200: #a1f2f6,
    300: #6ae3f0,
    400: #49cce7,
    500: #38b0da,
    600: #2296ca,
    700: #147fb5,
    800: #09699b,
    900: #005580,
  ),
  "confirm": (
    50: #e2edc8,
    100: #cde1a0,
    200: #bad579,
    300: #a9cc58,
    400: #94c337,
    500: #58ad23,
    600: #429f28,
    700: #218b3c,
    800: #1a7a38,
    900: #146935,
  ),
  "warning": (
    50: #fff9e2,
    100: #ffefb6,
    200: #ffe487,
    300: #ffdb57,
    400: #ffd132,
    500: #ffc911,
    600: #ffba0b,
    700: #fa0,
    800: #f48a03,
    900: #fd7205,
  ),
  "danger": (
    50: #ffebe7,
    100: #fecdc2,
    200: #fdb09d,
    300: #f97c61,
    400: #f2573c,
    500: #e83c27,
    600: #da291c,
    700: #c71811,
    800: #b10b07,
    900: #990300,
  ),
  "help": (
    50: #f3e5f5,
    100: #e1bee7,
    200: #ce93d8,
    300: #ba68c8,
    400: #ab47bc,
    500: #9c27b0,
    600: #8e24aa,
    700: #7b1fa2,
    800: #6a1b9a,
    900: #4a148c,
  ),
);
$theme-contrast-colors: (
  50: $dark-primary-text,
  100: $dark-primary-text,
  200: $dark-primary-text,
  300: $dark-primary-text,
  400: $dark-primary-text,
  500: $light-primary-text,
  600: $light-primary-text,
  700: $light-primary-text,
  800: $light-primary-text,
  900: $light-primary-text,
  A100: $dark-primary-text,
  A200: $light-primary-text,
  A400: $light-primary-text,
  A700: $light-primary-text,
);
