* {
  scrollbar-color: #c7d2d6 #ffffff;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply border border-solid border-white;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  border: 5px solid white;
  @apply rounded-lg bg-neutral-200;
}
