@use "@angular/material" as mat;
@use "../abstracts/colors" as *;
@use "../abstracts/functions" as *;

@include mat.all-component-typographies();
@include mat.core();

$dora-angular-primary: get-theme-color-contrast(inform);
$dora-angular-secondary: get-theme-color-contrast(danger);

$dora-angular-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($dora-angular-primary, 600),
      accent: mat.define-palette($dora-angular-secondary, 600),
      warn: mat.define-palette($dora-angular-secondary, 600),
    ),
  )
);

$dora-angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette($dora-angular-primary, 600),
      accent: mat.define-palette($dora-angular-secondary, 600),
      warn: mat.define-palette($dora-angular-secondary, 600),
    ),
  )
);

$mat-icon-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette($dora-angular-primary, 300),
      accent: mat.define-palette($dora-angular-secondary, 600),
      warn: mat.define-palette($dora-angular-secondary, 600),
    ),
  )
);

@each $color, $value in $theme-colors {
  .mat-#{$color} {
    color: mat.get-color-from-palette($value, 600);
    @each $shade, $hex in $value {
      &-#{$shade} {
        color: mat.get-color-from-palette($value, $shade);
      }
    }
  }
  .mat-badge-#{$color} .mat-badge-content {
    background: mat.get-color-from-palette($value, 600);
    color: get-badge-color($color);
  }
  @each $shade, $hex in $value {
    .mat-badge-#{$color}-#{$shade} .mat-badge-content {
      background: mat.get-color-from-palette($value, $shade);
      color: get-badge-color($color);
    }

    .mat-progress-spinner.mat-#{$color} {
      circle {
        stroke: mat.get-color-from-palette($value, 600);
      }
      &-#{$shade} circle {
        stroke: mat.get-color-from-palette($value, $shade);
      }
    }
  }
}

@include mat.all-component-colors($dora-angular-theme);

.dark-mode {
  @include mat.all-component-colors($dora-angular-dark-theme);
  @include mat.icon-theme($mat-icon-dark-theme);
}
