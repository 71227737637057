.has-icon-button .mat-form-field-flex {
  @apply pr-0 #{!important};
}

.mat-form-field {
  @apply w-full bg-transparent;

  &.mat-focused:not(&-disabled) .mat-form-field-flex {
    @apply border-2 border-inform-500;
  }

  &-disabled .mat-form-field-flex {
    @apply bg-neutral-50 text-neutral-900;

    .mat-chip {
      @apply text-neutral-900 #{!important};
    }
  }

  .mat-form-field-wrapper {
    @apply p-0 top-0 w-full;
  }

  .mat-form-field-flex {
    @apply flex items-center;
    @apply border border-neutral-200 rounded-sm w-full px-3;

    height: 36px;
  }

  .mat-form-field-infix {
    @apply border-0 p-0 w-full;

    line-height: 17px;
  }

  .mat-form-field-suffix {
    @apply flex;
  }

  .mat-form-field-label-wrapper {
    @apply absolute p-0 top-0 right-0 bottom-0 left-0 flex items-center w-full h-full;

    .mat-form-field-label {
      position: unset;
      @apply transform-none text-neutral-400;

      .mat-form-field-required-marker {
        @apply hidden;
      }
    }
  }

  .mat-form-field-subscript-wrapper,
  .mat-form-field-underline {
    display: none;
  }

  textarea:not(.mat-input-element) {
    caret-color: inherit;
    @apply truncate;
  }

  .mat-input-element,
  .mat-select {
    height: 36px;
  }

  ::placeholder {
    @apply text-neutral-400;
  }

  &.error:not(.mat-form-field-disabled) .mat-form-field-flex {
    @apply bg-danger-50;
  }

  &.error:not(.mat-focused) .mat-form-field-flex {
    @apply border border-danger-200;
  }

  &.warning:not(.mat-form-field-disabled) .mat-form-field-flex {
    @apply bg-warning-50;
  }

  &.warning:not(.mat-focused) .mat-form-field-flex {
    @apply border border-warning-600;
  }

  .mat-chip-list-wrapper {
    @apply flex flex-row flex-wrap items-center;
  }

  &.input-chip-field {
    .mat-form-field-flex {
      @apply h-auto;
      @apply border-none outline outline-1 outline-neutral-200 px-2;
    }

    .mat-chip-input {
      @apply h-auto min-h-[28px];
    }
  }
}

.textarea .mat-form-field-flex {
  @apply p-0 h-auto;

  .mat-form-field-infix {
    @apply overflow-hidden;
  }

  textarea {
    @apply m-0 px-3 py-2;
  }
}

.dark-mode .mat-form-field {
  input {
    @apply text-neutral-300;
  }

  .mat-form-field-flex {
    @apply border-neutral-600;
  }
}
