@use '@angular/material' as mat;

$default-font-family: system-ui, -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$inter-font-family: 'Inter', $default-font-family;
$rubik-font-family: 'Rubik', $default-font-family;
$font-size-10px: 0.625rem;
$font-size-12px: 0.75rem;
$font-size-13px: 0.8125rem;
$font-size-14px: 0.875rem;
$font-size-16px: 1rem;
$font-size-18px: 1.125rem;
$font-size-21px: 1.3125rem;
$font-size-24px: 1.5rem;
$headings: (
  h1: (
    value: $font-size-24px,
    'line-height': 28px,
    family: $rubik-font-family,
    weight: 500,
    color: 'neutral-900'
  ),
  h2: (
    value: $font-size-21px,
    'line-height': 25px,
    family: $rubik-font-family,
    weight: 500,
    color: 'neutral-900'
  ),
  h3: (
    value: $font-size-18px,
    'line-height': 22px,
    family: $inter-font-family,
    weight: 500,
    color: 'neutral-900'
  ),
  h4: (
    value: $font-size-16px,
    'line-height': 19px,
    family: $inter-font-family,
    weight: 500,
    color: 'inform-600'
  ),
  h5: (
    value: $font-size-14px,
    'line-height': 17px,
    family: $inter-font-family,
    weight: 500,
    color: 'inform-600'
  ),
  h6: (
    value: $font-size-10px,
    'line-height': 12px,
    family: $inter-font-family,
    weight: 500,
    color: 'neutral-600'
  )
);
