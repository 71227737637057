.standard-button.mdc-button {
  @apply rounded-md;

  height: 36px;

  &.secondary-button {
    @apply text-neutral-800 bg-white border border-neutral-200;

    .mat-icon {
      @apply text-neutral-600;
    }

    &:hover {
      @apply bg-neutral-10 text-neutral-600;

      .mat-icon {
        @apply text-neutral-400;
      }
    }

    &:focus {
      @apply bg-white;

      border: 3px solid rgb(39 150 220 / 33%);
    }

    &:active {
      @apply text-neutral-600 bg-neutral-25 border border-neutral-200;
    }
  }

  &.danger-button {
    @apply bg-danger-600 text-white;

    &:hover {
      @apply bg-danger-500 text-danger-50;
    }

    &:focus {
      @apply bg-danger-500;

      border: 3px solid rgb(218 41 28 / 33%);
    }

    &:active {
      @apply border border-transparent;

      color: rgba(#fff, 0.8);
    }
  }

  &.error-mode {
    @apply bg-white text-danger-600;
  }

  &:disabled,
  &[disabled] {
    @apply bg-neutral-50 text-neutral-400 border-transparent #{!important};

    .key {
      @apply text-neutral-300 bg-transparent border-neutral-100 cursor-default #{!important};
    }

    .mat-icon {
      @apply text-neutral-400;
    }
  }

  &.icon-only {
    @apply px-0 #{!important};

    min-width: 40px;

    .mat-icon {
      @apply text-neutral-600;
    }
  }
}

.special-button.mat-mini-fab {
  @apply rounded-xl bg-white;

  border: 1px solid rgb(91 103 111 / 10%);
  box-shadow: 0 0 2px rgb(0 0 0 / 5%), 0 3px 6px rgb(0 0 0 / 5%),
    0 1px 3px rgb(0 0 0 / 10%), 0 8px 64px rgb(0 0 0 / 15%) !important;

  .mat-button-wrapper {
    @apply p-0;
  }

  .mat-icon {
    @apply text-neutral-600;
  }

  &:hover {
    @apply bg-neutral-10 border-neutral-200;

    .mat-icon {
      @apply text-neutral-400;
    }
  }

  &:focus {
    border: 3px solid rgb(171 71 188 / 33%);
  }

  &:active {
    @apply bg-neutral-25 border border-neutral-200;
  }

  &.unread-button {
    @apply bg-help-500 text-white;

    border-color: rgb(91 103 111 / 10%);
    min-width: 67px;
    width: auto;

    .mat-icon {
      @apply text-white;
    }

    &:hover {
      @apply bg-help-400;

      .mat-icon {
        @apply text-help-100;

        + span {
          @apply text-help-50;
        }
      }
    }

    &:focus {
      @apply bg-help-400;

      border: 3px solid rgb(171 71 188 / 33%);
    }
  }

  &.primary-button {
    min-width: 67px;
    width: auto;
  }

  &.close-button {
    @apply border-neutral-200 shadow-none #{!important};

    &:focus {
      border: 3px solid rgb(39 150 220 / 33%) !important;
    }
  }

  &.btn-sm {
    height: 20px;
    width: 20px;
  }

  &.btn-lg {
    height: 40px;
    width: 40px;
  }
}

.standard-button.mdc-button,
.special-button.mat-mini-fab {
  &.primary-button:not(:disabled),
  &.primary-button:not([disabled]) {
    @apply text-white bg-inform-500 border-transparent;

    .mat-icon {
      @apply text-white;
    }

    .key {
      @apply text-white bg-transparent border-inform-100;
    }

    &:hover {
      @apply bg-inform-400;

      .mat-icon + span {
        @apply text-inform-50;
      }
    }

    &:focus {
      @apply bg-inform-400;

      border: 3px solid rgb(39 150 220 / 33%);
    }

    &:active {
      @apply border border-transparent;

      color: rgba(#fff, 0.8);
    }
  }

  .mat-button-wrapper {
    @apply flex items-center justify-center;

    * {
      line-height: 17px;
    }
  }

  .mat-button-focus-overlay {
    display: none;
  }

  &:focus {
    background-clip: padding-box;
  }

  &:disabled,
  &[disabled] {
    @apply bg-neutral-50 text-neutral-400 border-transparent #{!important};

    .key {
      @apply text-neutral-300 bg-transparent border-neutral-100 cursor-default #{!important};
    }

    .mat-icon {
      @apply text-neutral-400;
    }
  }
}

button:focus {
  outline: 0;
}

.mat-icon-button {
  @apply inline-flex w-10 h-10 #{!important};
  @apply justify-center items-center;
}

.dark-mode {
  .standard-button.mdc-button {
    &.secondary-button {
      @apply text-neutral-100 bg-neutral-700 border border-neutral-600;

      .mat-icon {
        @apply text-neutral-300;
      }
    }
  }

  .special-button.mat-mini-fab {
    @apply bg-white;
  }
}
