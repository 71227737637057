.mat-mdc-snack-bar-container {
  --tw-text-opacity: 1;
  --mat-mdc-snack-bar-button-color: rgb(255 255 255 / var(--tw-text-opacity));
  --mdc-snackbar-supporting-text-color: rgb(
    255 255 255 / var(--tw-text-opacity)
  );
  .mdc-button {
    @apply border-none #{!important};
  }
  .mat-icon {
    margin-left: 0.25rem !important;
  }
}

.snackbar {

  &.snackbar-error {
    --mdc-snackbar-container-color: #c71811;
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #429f28;
  }
  &.snackbar-info {
    --mdc-snackbar-container-color: #38b0da;
  }
  &.snackbar-warning {
    .mat-icon {
      @apply text-white;
    }
    --mdc-snackbar-container-color: #fa0;
    --mdc-snackbar-supporting-text-color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .mat-button-wrapper {
      color: black !important; // action text color
  }
}
