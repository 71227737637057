@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: url('~src/assets/fonts/inter/bold/inter-bold.eot');
  src: url('~src/assets/fonts/inter/bold/inter-bold.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/inter/bold/inter-bold.woff2') format('woff2'),
    url('~src/assets/fonts/inter/bold/inter-bold.woff') format('woff'),
    url('~src/assets/fonts/inter/bold/inter-bold.ttf') format('truetype'),
    url('~src/assets/fonts/inter/bold/inter-bold.svg#inter-bold') format('svg');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url('~src/assets/fonts/inter/medium/inter-medium.eot');
  src: url('~src/assets/fonts/inter/medium/inter-medium.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/inter/medium/inter-medium.woff2') format('woff2'),
    url('~src/assets/fonts/inter/medium/inter-medium.woff') format('woff'),
    url('~src/assets/fonts/inter/medium/inter-medium.ttf') format('truetype'),
    url('~src/assets/fonts/inter/medium/inter-medium.svg#inter-medium')
      format('svg');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  src: url('~src/assets/fonts/inter/regular/inter-regular.eot');
  src: url('~src/assets/fonts/inter/regular/inter-regular.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/inter/regular/inter-regular.woff2') format('woff2'),
    url('~src/assets/fonts/inter/regular/inter-regular.woff') format('woff'),
    url('~src/assets/fonts/inter/regular/inter-regular.ttf') format('truetype'),
    url('~src/assets/fonts/inter/regular/inter-regular.svg#inter-regular')
      format('svg');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  src: url('~src/assets/fonts/rubik/bold/rubik-bold.eot');
  src: url('~src/assets/fonts/rubik/bold/rubik-bold.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/rubik/bold/rubik-bold.woff2') format('woff2'),
    url('~src/assets/fonts/rubik/bold/rubik-bold.woff') format('woff'),
    url('~src/assets/fonts/rubik/bold/rubik-bold.ttf') format('truetype'),
    url('~src/assets/fonts/rubik/bold/rubik-bold.svg#rubik-bold') format('svg');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: url('~src/assets/fonts/rubik/medium/rubik-medium.eot');
  src: url('~src/assets/fonts/rubik/medium/rubik-medium.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/rubik/medium/rubik-medium.woff2') format('woff2'),
    url('~src/assets/fonts/rubik/medium/rubik-medium.woff') format('woff'),
    url('~src/assets/fonts/rubik/medium/rubik-medium.ttf') format('truetype'),
    url('~src/assets/fonts/rubik/medium/rubik-medium.svg#rubik-medium')
      format('svg');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  src: url('~src/assets/fonts/rubik/regular/rubik-regular.eot');
  src: url('~src/assets/fonts/rubik/regular/rubik-regular.eot?#iefix')
      format('embedded-opentype'),
    url('~src/assets/fonts/rubik/regular/rubik-regular.woff2') format('woff2'),
    url('~src/assets/fonts/rubik/regular/rubik-regular.woff') format('woff'),
    url('~src/assets/fonts/rubik/regular/rubik-regular.ttf') format('truetype'),
    url('~src/assets/fonts/rubik/regular/rubik-regular.svg#rubik-regular')
      format('svg');
}
