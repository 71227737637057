%center-positioned {
  @apply top-0 left-0 bottom-0 right-0;
}

%width-transition {
  transition: width ease-in-out, 0.2s top 0.2s ease-in-out;
  @apply w-full;
}

.movable-arrow {
  transition: transform 0.25s ease-in-out;

  &.arrow-moved {
    transform: rotate(180deg);
  }
}

.bottom-fixed {
  @extend %width-transition;
  @apply fixed left-0 flex flex-wrap items-end z-50;
}

.scrollable {
  @extend %width-transition;
  @apply fixed #{!important};
  @apply bg-white z-50;

  &.shadow-complex {
    box-shadow: 0 1px 0 rgb(0 0 0 / 8%), 0 3px 16px rgb(0 0 0 / 5%),
      0 2px 4px rgb(0 0 0 / 5%);
  }

  &.shadow-simple {
    box-shadow: 0 1px 0 rgb(58 87 108 / 15%);
  }

  &.top-border {
    @apply border-solid border-t-4 border-inform-600;
  }
}

.bg-inactive {
  background: repeating-linear-gradient(
    45deg,
    theme('colors.neutral.25'),
    theme('colors.neutral.25') 8px,
    #fff 8px,
    #fff 20px
  );
  @apply bg-fixed;
}
